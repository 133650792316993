'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import useAnalytics from '@/lib/hooks/use-analytics';
import { useGlobalState } from '../state';

export default function AppRouterRouteTransitionManager() {
  const pathname = usePathname();
  const { analyticEvents, analyticsLoaded } = useAnalytics();
  const { state } = useGlobalState();
  const uid = state?.user?.data?.uid;
  const ref = useRef<string>('');

  useEffect(() => {
    const logPageView = () => {
      if (ref.current === pathname) return;
      analyticEvents.pageView(pathname);
      ref.current = pathname;
    };

    if (analyticsLoaded && uid) {
      logPageView();
    }
  }, [pathname, analyticEvents, analyticsLoaded, uid]);

  return null;
}